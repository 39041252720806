<template lang="pug">
modal(size='sm' @close='$emit("close")')
  modal-header {{ $t('Edit Tag') }}
  generic-form#FormTagEdit(:endpoint='`/_/Projects/${projectId}/ProjectTags/*`' @submitSuccess='onSubmitSuccess')
    template(#default)
      form-hidden(name='TagName' :modelValue='tagName')
      form-input(name='NewTagName' :modelValue='tagName' :autofocus='true')
      form-footer
        form-action(:primary='true' name='EditTag')
</template>

<script lang="ts">
import { useProjectsStore } from '../store'
import { type IProjectData } from '../types'
import { type IFormResponseInfo } from '@/components/GenericForm/types'
import { defineComponent } from 'vue'

const FormProjectTagEdit = defineComponent({
  inheritAttrs: false,
  props: {
    tagName: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  setup(props, context) {
    const store = useProjectsStore()
    return {
      onSubmitSuccess: (e: IFormResponseInfo<IProjectData>) => {
        store.setProject(e.data)
        context.emit('close')
      },
    }
  },
})
export default FormProjectTagEdit
</script>
